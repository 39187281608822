import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"mb-3",attrs:{"color":"white","outlined":""},on:{"click":function($event){return _vm.$emit('closeMap', true)}}},[_vm._v(" Back ")]),_c('ServerSideDataTable',{attrs:{"headers":_vm.headers,"items":_vm.filteredRegions,"sort-by":['city'],"loading":_vm.isLoading,"handle-page-change":_vm.handlePageChange,"handle-page-size-change":_vm.handlePageSizeChange,"current-page":_vm.page,"hide-entries":"","expand":"","page-size":_vm.size,"expanded-value":_vm.prefillValue,"itemKey":"country","total-item-length":_vm.filteredRegions.length},on:{"onExpanded":_vm.handleExpanded},scopedSlots:_vm._u([{key:"search-bar",fn:function(){return [_c('SearchBar',{staticClass:"justify-end",attrs:{"label":"Search","filteredItems":_vm.filteredRegions,"item-text":"country","item-value":"country","return-object":"","with-dropdown":""},on:{"input":_vm.handleOnSearch,"itemSelected":_vm.handleSelectedItem},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}},[_c('div',[_c('h1',[_vm._v("Requested Services")]),_c('p',[_vm._v("List of all services ordered by customers")])])])]},proxy:true},{key:"expanded-item",fn:function(ref){
var subItem = ref.item;
return [_c('td',{staticClass:"expanded-wrapper",attrs:{"colspan":"6"}},[_c('ServerSideDataTable',{staticClass:"expanded",attrs:{"headers":_vm.headers,"items":subItem ? subItem.services : [],"sort-by":['city'],"loading":_vm.isLoading,"pageSize":1000,"hide-entries":"","hide-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c(VContainer,[(item.status != 'Cancelled')?_c(VBtn,{staticClass:"btn-sending",attrs:{"dark":"","small":""},on:{"click":function($event){return _vm.provideService(subItem, index)}}},[_vm._v(" Provide service ")]):_vm._e()],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.city)+" ")]}},{key:"item.number_request",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.number_request > 1000 ? "1000+" : item.number_request)+" ")]}},{key:"item.total_amount_dbio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalValue.dbio.toFixed(3))+" ")]}},{key:"item.total_amount_usd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalValue.usd.toFixed(3))+" ")]}}],null,true)})],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[
            'row-location d-flex align-center',
            {'row-location--active': item.isExpanded}
          ]},[_c('span',[_vm._v(_vm._s(item.country))])])]}},{key:"item.number_request",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.number_request > 1000 ? "1000+" : item.number_request)+" ")]}},{key:"item.total_amount_dbio",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_amount_dbio.toFixed(3))+" ")]}},{key:"item.total_amount_usd",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_amount_usd.toFixed(3))+" ")]}},{key:"item.actions",fn:function(){return [_vm._v("-")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
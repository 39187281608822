import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ServerSideDataTable from "@/components/DataTable/ServerSideDataTable";
import SearchBar from "../Mapping/SearchBar";
import localStorage from "@/lib/local-storage";
export default {
  name: "RequestLabServiceTable",
  props: {
    countryExpand: {
      type: String,
      default: ""
    },
    countries: {
      type: Array,
      default: () => []
    },
    size: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    }
  },
  components: {
    ServerSideDataTable,
    SearchBar
  },
  data: () => ({
    headers: [{
      text: "Location",
      value: "country",
      sortable: false,
      width: "270px"
    }, {
      text: "Number of requests",
      value: "totalRequests",
      sortable: false,
      align: "center",
      width: 160
    }, {
      text: "Service",
      value: "category",
      sortable: false,
      width: "270px"
    }, {
      text: "Total Amount (in DBIO)",
      value: "total_amount_dbio",
      sortable: false,
      align: "center",
      width: "200px"
    }, {
      text: "Total Amount (in USD)",
      value: "total_amount_usd",
      sortable: false,
      align: "center",
      width: "200px"
    }, {
      text: "Actions",
      value: "actions",
      sortable: false,
      align: "center"
    }],
    regions: [],
    // page: 1,
    chevronIconChange: false,
    pageSize: 10,
    address: "",
    onSelectedItem: false,
    password: "",
    searchQuery: "",
    isLoading: false
  }),
  created() {
    this.fetchRegions();
    this.searchQuery = this.countryExpand;
  },
  computed: {
    filteredRegions() {
      const filtered = this.regions.filter(region => this.searchQuery.toLowerCase().split(" ").every(v => region.country.toLowerCase().includes(v)));
      return !this.onSelectedItem && !this.searchQuery ? this.regions : filtered;
    },
    prefillValue() {
      return this.regions.filter(region => region.country.toLowerCase() === this.countryExpand.toLowerCase());
    }
  },
  methods: {
    handleOnSearch(val) {
      this.onSelectedItem = false;
      this.searchQuery = val;
      if (!this.onSelectedItem && !val) this.fetchRegions();
    },
    handleExpanded(expanded, id) {
      this.chevronIconChange = expanded;
      this.regions.forEach(region => {
        region.isExpanded = false;
        if (region.countryId === id) region.isExpanded = expanded;
      });
    },
    async fetchRegions() {
      const counter = (arrayCounters, key, childKey) => {
        return arrayCounters.reduce((accumulator, v) => {
          if (childKey) return accumulator + v[key][childKey];else return accumulator + v[key];
        }, 0);
      };
      this.regions = this.countries.map(region => ({
        ...region,
        number_request: counter(region.services, "totalRequests"),
        total_amount_dbio: counter(region.services, "totalValue", "dbio"),
        total_amount_usd: counter(region.services, "totalValue", "usd"),
        isExpanded: false
      })).sort((a, b) => {
        var nameA = a.country.toLowerCase();
        var nameB = b.country.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    provideService(item, idx) {
      const camelize = str => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, "");
      };
      item.services = item.services.sort(function (a, b) {
        if (a.city < b.city) return -1;
        if (a.city > b.city) return 1;
        return 0;
      });
      const keystore = localStorage.getAddress();
      const isLoggedIn = !!keystore;
      const payload = {
        category: item.services[idx].category,
        country: {
          code: item.services[idx].countryId,
          name: item.country
        },
        city: {
          code: item.services[idx].regionCode,
          name: item.services[idx].city
        },
        serviceFlow: "StakingRequestService"
      };
      const parameterQueries = Object.entries(payload).reduce((filtered, _ref) => {
        let [key, value] = _ref;
        if (typeof value === "object") {
          const nested = Object.entries(value).reduce((nestedFiltered, _ref2) => {
            let [nestedKey, nestedValue] = _ref2;
            nestedFiltered = {
              ...nestedFiltered,
              ["".concat(camelize("".concat(key, " ").concat(nestedKey)))]: nestedValue
            };
            return nestedFiltered;
          }, {});
          filtered = {
            ...filtered,
            ...nested
          };
        }
        filtered = {
          ...filtered,
          ...(typeof value === "object" ? {} : {
            [key]: value
          })
        };
        return filtered;
      }, {});
      this.$store.dispatch("lab/setProvideService", {
        ...parameterQueries,
        serviceFlow: "StakingRequestService"
      });
      if (!isLoggedIn) {
        this.$router.push({
          name: "login",
          query: {
            redirect: "lab-dashboard-add-services"
          }
        });
        return;
      }
      this.$router.push({
        name: "lab-dashboard-add-services"
      });
    },
    handleSelectedItem(item) {
      this.onSelectedItem = true;
      this.regions = this.regions.filter(region => region.countryId === item.countryId);
    },
    async handlePageChange(value) {
      this.page = value;
      await this.loadData();
    },
    async handlePageSizeChange(value) {
      // this.pageSize = value
      this.size = value;
      this.page = 1; // If change page size restart from page 1
      await this.loadData();
    }
  }
};
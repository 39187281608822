import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as d3 from "d3";
import * as topojson from "topojson";
import { convertSLug } from "@/utils/convertSlug";
export default {
  name: "MapCountry",
  props: {
    serviceRequestByCountry: Object
  },
  data: () => ({
    selectedContinent: "World",
    width: null,
    height: null,
    data: new Map(),
    searchQuery: "",
    province: null,
    currentProvince: null,
    tooltipTotalRequest: 0,
    tooltipTotalValue: 0,
    tooltipCountry: "",
    active: false,
    countries: []
  }),
  computed: {
    filteredResults() {
      return this.searchQuery ? this.countries.find(country => this.searchQuery.toLowerCase().split(" ").every(v => country.name.toLowerCase().includes(v))) : null;
    }
  },
  watch: {
    filteredResults(val) {
      var _d3$select, _d3$select$_groups$0$;
      const svg = d3.select("#map");
      svg.attr("width", Math.round(document.querySelector(".v-card").getBoundingClientRect().width));
      svg.attr("height", 739);
      const svgWidth = +svg.attr("width");
      const svgHeight = +svg.attr("height");
      const projection = d3.geoNaturalEarth1().scale(300).center([0, 10]).translate([svgWidth / 2, svgHeight / 2]);
      const path = d3.geoPath().projection(projection);
      var zoom = d3.zoom().scaleExtent([1 / 2, 4]).on("zoom", zoomed);
      if (!(val !== null && val !== void 0 && val.name)) {
        d3.selectAll(".country").transition().duration(200).style("opacity", .5);
        svg.transition().duration(500).call(zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1));
        return;
      }
      function zoomed(event) {
        const g = d3.select("g");
        g.attr("transform", "translate(".concat(event.transform.x, ", ").concat(event.transform.y, ") scale(").concat(event.transform.k, ")"));
      }
      function boxZoom(box, centroid, paddingPerc, name) {
        let minXY = box[0];
        let maxXY = box[1];
        let zoomWidth = Math.abs(minXY[0] - maxXY[0]);
        let zoomHeight = Math.abs(minXY[1] - maxXY[1]);
        let zoomMidX = centroid[0];
        let zoomMidY = centroid[1];
        zoomWidth = zoomWidth * (1 + paddingPerc / 100);
        zoomHeight = zoomHeight * (1 + paddingPerc / 100);
        let maxXscale = document.querySelector("#map").getBoundingClientRect().width / zoomWidth;
        let maxYscale = document.querySelector("#map").getBoundingClientRect().height / zoomHeight;
        let zoomScale = Math.min(maxXscale, maxYscale);
        let offsetX = zoomScale * zoomMidX;
        let offsetY = zoomScale * zoomMidY;
        let dleft = Math.min(0, document.querySelector("#map").getBoundingClientRect().width / 2 - offsetX);
        let dtop = Math.min(0, document.querySelector("#map").getBoundingClientRect().height / 2 - offsetY);
        dleft = Math.max(document.querySelector("#map").getBoundingClientRect().width - svgWidth * zoomScale, dleft) + 220;
        dtop = Math.max(document.querySelector("#map").getBoundingClientRect().height - svgHeight * zoomScale, dtop);

        // Custom coordinate translate for Australia
        zoomScale = name ? 4 : zoomScale;
        dleft = name ? -4348 : dleft;
        dtop = name ? -1884 : dtop;
        svg.transition().duration(500).call(zoom.transform, d3.zoomIdentity.translate(dleft, dtop).scale(zoomScale));
      }
      d3.selectAll(".country").transition().duration(200).style("opacity", .5);
      d3.select("#".concat(convertSLug(val.name))).transition().duration(200).style("opacity", 1);
      const country = (_d3$select = d3.select("#".concat(convertSLug(val.name)))) === null || _d3$select === void 0 ? void 0 : (_d3$select$_groups$0$ = _d3$select._groups[0][0]) === null || _d3$select$_groups$0$ === void 0 ? void 0 : _d3$select$_groups$0$.__data__;
      const isAustralia = country.properties.name === "Australia";
      if (!country) return;
      boxZoom(path.bounds(country), path.centroid(country), 70, isAustralia);
    }
  },
  mounted() {
    this.renderD3();
  },
  methods: {
    handleSeeDetails(country) {
      this.$emit("openList", false, country);
    },
    koreaFilter(name) {
      if (name === "Dem. Rep. Korea") return "North Korea";else if (name === "Korea") return "South Korea";else return name;
    },
    createTooltip(_ref) {
      var _totalValue$dbio;
      let {
        country,
        totalValue,
        totalRequests
      } = _ref;
      return "\n        <h3 class=\"tooltip__header\">".concat(country, "</h3>\n        <div class=\"tooltip__content\">\n          <div class=\"tooltip__field d-flex justify-between\">\n            <p class=\"tooltip__field-title mb-0 mr-8\">Categories Requested</p>\n            <b class=\"tooltip__field-desc mb-0\">").concat(totalRequests, "</b>\n          </div>\n          <div class=\"tooltip__field d-flex justify-between\">\n            <p class=\"tooltip__field-title mb-0 mr-8\">Value staked</p>\n            <b class=\"tooltip__field-desc mb-0\">").concat(totalValue === null || totalValue === void 0 ? void 0 : (_totalValue$dbio = totalValue.dbio) === null || _totalValue$dbio === void 0 ? void 0 : _totalValue$dbio.toFixed(3), "</b>\n          </div>\n        </div>\n      ");
    },
    renderD3() {
      const context = this;
      const serviceRequestByCountry = {
        ...this.serviceRequestByCountry
      };
      const svg = d3.select("#map");
      svg.attr("width", Math.round(document.querySelector(".v-card").getBoundingClientRect().width));
      svg.attr("height", Math.round(document.querySelector(".v-card").getBoundingClientRect().height));
      const svgWidth = +svg.attr("width");
      const svgHeight = +svg.attr("height");
      const projection = d3.geoNaturalEarth1().scale(300).center([0, 10]).translate([svgWidth / 2, svgHeight / 2]);
      const pathGenerator = d3.geoPath().projection(projection);
      const g = svg.append("g");
      var zoom = d3.zoom().scaleExtent([1 / 2, 4]).on("zoom", zoomed);
      svg.call(zoom);
      function zoomed(event) {
        const g = d3.select("g");
        g.attr("transform", "translate(".concat(event.transform.x, ", ").concat(event.transform.y, ") scale(").concat(event.transform.k, ")"));
      }
      function transition(zoomLevel) {
        svg.transition().delay(100).duration(700).call(zoom.scaleBy, zoomLevel);
      }
      d3.selectAll("button").on("click", function () {
        if (this.id === "zoom_in") {
          transition(2);
        }
        if (this.id === "zoom_out") {
          transition(-2);
        }
      });
      function boxZoom(box, centroid, paddingPerc, name) {
        let minXY = box[0];
        let maxXY = box[1];
        let zoomWidth = Math.abs(minXY[0] - maxXY[0]);
        let zoomHeight = Math.abs(minXY[1] - maxXY[1]);
        let zoomMidX = centroid[0];
        let zoomMidY = centroid[1];
        zoomWidth = zoomWidth * (1 + paddingPerc / 100);
        zoomHeight = zoomHeight * (1 + paddingPerc / 100);
        let maxXscale = document.querySelector("#map").getBoundingClientRect().width / zoomWidth;
        let maxYscale = document.querySelector("#map").getBoundingClientRect().height / zoomHeight;
        let zoomScale = Math.min(maxXscale, maxYscale);
        let offsetX = zoomScale * zoomMidX;
        let offsetY = zoomScale * zoomMidY;
        let dleft = Math.min(0, document.querySelector("#map").getBoundingClientRect().width / 2 - offsetX);
        let dtop = Math.min(0, document.querySelector("#map").getBoundingClientRect().height / 2 - offsetY);
        dleft = Math.max(document.querySelector("#map").getBoundingClientRect().width - svgWidth * zoomScale, dleft) + 220;
        dtop = Math.max(document.querySelector("#map").getBoundingClientRect().height - svgHeight * zoomScale, dtop);

        // Custom coordinate translate for Australia
        zoomScale = name ? 4 : zoomScale;
        dleft = name ? -4348 : dleft;
        dtop = name ? -1884 : dtop;
        svg.transition().duration(500).call(zoom.transform, d3.zoomIdentity.translate(dleft, dtop).scale(zoomScale));
      }
      const countryColorScale = val => {
        var _serviceRequestByCoun;
        let country;
        country = context.koreaFilter(val.properties.name);
        let colorIndex = 0;
        const colors = ["#FFFFFF", "#ACDFE3", "#5DC9CC", "#079DAB", "#FDD07D", "#F7C192", "#D9442C"];
        if (serviceRequestByCountry[country] === undefined) colors[colorIndex];
        const request = (_serviceRequestByCoun = serviceRequestByCountry[country]) === null || _serviceRequestByCoun === void 0 ? void 0 : _serviceRequestByCoun.totalRequests;
        if (request !== 0 && request <= 5) colorIndex = 1;
        if (request > 5) colorIndex = 2;
        if (request > 10) colorIndex = 3;
        if (request > 20) colorIndex = 4;
        if (request > 25) colorIndex = 5;
        if (request > 30) colorIndex = 6;
        return colors[colorIndex];
      };
      const loadAndProcessData = () => Promise.all([d3.tsv("https://unpkg.com/world-atlas@1.1.4/world/50m.tsv"), d3.json("https://unpkg.com/world-atlas@1.1.4/world/50m.json")]).then(_ref2 => {
        let [tsvData, topoJSONdata] = _ref2;
        const rowById = tsvData.reduce((accumulator, d) => {
          accumulator[d.iso_n3] = d;
          return accumulator;
        }, {});
        const countries = topojson.feature(topoJSONdata, topoJSONdata.objects.countries);
        countries.features.forEach(d => {
          Object.assign(d.properties, rowById[d.id]);
        });
        context.countries = countries.features.map(map => {
          let country;
          country = {
            name: context.koreaFilter(map.properties.name)
          };
          const service = serviceRequestByCountry[country.name];
          if (service !== undefined) country.services = service.services;
          return country;
        });
        return countries;
      });
      loadAndProcessData().then(countries => {
        var tooltip = d3.select("#map-container").append("div").attr("class", "tooltip").style("visibility", "hidden").style("min-width", "250px").html(context.createTooltip({}));
        g.selectAll("path").data(countries.features).enter().append("path").attr("class", "country").attr("d", pathGenerator).attr("fill", d => countryColorScale(d)).attr("id", d => convertSLug(context.koreaFilter(d.properties.name))).on("mouseenter", function (e) {
          d3.selectAll(".country").transition().duration(200).style("opacity", .5);
          d3.select(this).transition().duration(200).style("opacity", 1);
          let country;
          country = context.koreaFilter(e.target.__data__.properties.name);
          if (serviceRequestByCountry[country] != undefined) {
            const {
              totalRequests,
              totalValue
            } = serviceRequestByCountry[country];
            return tooltip.html(context.createTooltip({
              country,
              totalValue,
              totalRequests
            })).style("opacity", "1").style("visibility", "visible");
          }
        }).on("mousemove", function (e) {
          return tooltip.style("top", e.pageY - 70 + "px").style("left", e.pageX - 330 + "px");
        }).on("mouseout", function () {
          d3.selectAll(".country").transition().duration(200).style("opacity", 1);
          d3.select(this).transition().duration(200);
          return tooltip.style("visibility", "hidden").style("opacity", "0");
        }).on("click", function (e) {
          const country = e.target.__data__;
          const isAustralia = country.properties.name === "Australia" ? country.properties.name : null;
          context.searchQuery = context.koreaFilter(country.properties.name);
          boxZoom(pathGenerator.bounds(country), pathGenerator.centroid(country), 70, isAustralia);
        });
      });
    }
  }
};
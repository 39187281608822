import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//

import ServiceTable from "./ServiceTable";
import MapCountry from "./Mapping/MapCountry";
import serviceHandler from "@/mixins/serviceHandler";
import { getServiceRequestCountries } from "@/lib/api";
export default {
  name: "RequestLab",
  mixins: [serviceHandler],
  components: {
    ServiceTable,
    MapCountry
  },
  data() {
    return {
      map: true,
      country: null,
      countries: [],
      serviceRequestByCountry: {},
      isLoading: true,
      size: 10,
      page: 1
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchServiceRequestCountries();
    this.isLoading = false;
  },
  methods: {
    async fetchServiceRequestCountries() {
      try {
        const serviceRequests = await getServiceRequestCountries();
        this.countries = serviceRequests;
        for (let req of serviceRequests) {
          if (this.serviceRequestByCountry[req.country] == undefined) {
            this.serviceRequestByCountry[req.country] = {
              ...req
            };
          } else {
            this.serviceRequestByCountry[req.country].totalRequests += req.totalRequests;
            this.serviceRequestByCountry[req.country].totalRequests += req.totalValue;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    handleOpenList(mapState, countryName) {
      this.map = mapState;
      this.country = countryName;
    }
  }
};